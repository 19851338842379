import axios from 'axios';
import { CharacterType, CharactersResponse } from '../types';

const BASE_URL = process.env.REACT_APP_SWAPI_BASE_URL;

export const getCharacters = async (
  url: string,
): Promise<CharactersResponse> => {
  const { data } = await axios.get<CharactersResponse>(url);
  return data;
};

export const getCharacterById = async (id: string): Promise<CharacterType> => {
  const response = await axios.get<CharacterType>(`${BASE_URL}people/${id}`);
  return response.data;
};

export const getFilmsById = async (url: string) => {
  const response = await axios.get(url);
  return response.data;
};

export const getPlanetsById = async (url: string) => {
  const response = await axios.get(url);
  return response.data;
};

export const getSpeciesById = async (url: string) => {
  const response = await axios.get(url);
  return response.data;
};

export const getVehiclesById = async (url: string) => {
  const response = await axios.get(url);
  return response.data;
};

export const getStarshipsById = async (url: string) => {
  const response = await axios.get(url);
  return response.data;
};
