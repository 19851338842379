import React, { useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

const About: React.FC = () => {
  const [language, setLanguage] = useState('de');

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    setLanguage(event.target.value);
  };

  return (
    <Container className="my-4">
      <Row className="d-flex justify-content-center align-items-center mb-5">
        <Col xs={10}>
          <h1>
            {language === 'en'
              ? 'About Star Wars and This App'
              : 'Über Star Wars und diese App'}
          </h1>
        </Col>
        <Col xs={2}>
          <Form.Group controlId="languageSelect">
            <Form.Label>
              {language === 'en' ? 'Choose Language' : 'Sprache wählen'}
            </Form.Label>
            <Form.Select value={language} onChange={handleLanguageChange}>
              <option value="en">English</option>
              <option value="de">Deutsch</option>
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      {language === 'en' ? (
        <Row>
          <Col xs={12}>
            <h2>About Star Wars</h2>
            <p>
              Star Wars is a popular franchise created by George Lucas. The
              series is set in a distant galaxy and follows the adventures of
              various characters including Jedi, Sith, droids, and more. It
              began with the original 1977 film "Star Wars" (later rebranded as
              "Star Wars: Episode IV – A New Hope") and has since expanded into
              a vast universe with movies, TV shows, books, comics, and other
              media. The core themes revolve around the struggle between the
              forces of good (the Jedi) and evil (the Sith).
            </p>
            <p>
              The franchise is renowned for its epic storytelling, memorable
              characters, and groundbreaking special effects. The saga has
              captivated audiences for decades and remains a significant part of
              popular culture.
            </p>
          </Col>
          <Col xs={12}>
            <h2>About This App</h2>
            <p>
              This application, "Star Wars Character of the Day," is designed to
              bring the Star Wars universe to your fingertips. It showcases a
              daily dose of Star Wars characters, providing information about
              each character and their role in the saga.
            </p>
            <p>
              The app is built using React for the frontend, with TypeScript for
              type safety. Bootstrap is used for styling and layout, providing a
              responsive design that adapts to various screen sizes. The app
              fetches character data from the Star Wars API (SWAPI) to display
              character information dynamically.
            </p>
            <p>
              The application uses Redux for state management, allowing for
              centralized control over application state. Redux helps in
              managing user authentication, character data, and other global
              states.
            </p>
            <p>
              Actions and reducers are used to handle user login, logout, and
              store user information such as login status and user data.
            </p>
            <p>Key features include:</p>
            <ul>
              <li>Displaying a list of Star Wars characters</li>
              <li>Infinite scrolling to load more characters as you scroll</li>
              <li>A detailed view of each character's information</li>
              <li>Redux store management for global state handling</li>
              <li>User creation and login functionality</li>
              <li>Character of the Day feature</li>
            </ul>
            <h3>User Creation and Login</h3>
            <p>
              Users can create an account and log in to the application. Upon
              logging in, user-specific data such as login status and user
              information are stored in the Redux state. This feature ensures a
              personalized experience and allows for secure access to user data.
            </p>
            <p>
              The app also integrates Google Single Sign-On (SSO) to provide a
              seamless and secure authentication experience. With Google SSO,
              users can log in using their Google account credentials, which
              simplifies the login process and enhances security.
            </p>
            <p>
              To implement this feature, we set up Google SSO by creating OAuth
              2.0 credentials through the Google Cloud Console. This involved
              generating a Client ID, which allows the app to communicate with
              Google's authentication servers. When users choose to log in via
              Google, they are redirected to Google's sign-in page, where they
              can securely enter their credentials.
            </p>
            <p>
              Once authenticated, the app receives key user information, such as
              the user's email address, directly from Google. This information
              is used to personalize the user's experience within the app
              without requiring them to manage a separate set of credentials.
              <span className="text-decoration-underline ms-1">
                Google SSO is possible only with a specific set of accounts
                enabled by us, which ensures that only authorized users can
                access the app. The list of these enabled accounts can be found
                in the README file.
              </span>
            </p>
            <p>
              The integration of Google SSO not only streamlines the login
              process but also leverages Google's security measures, ensuring
              that user data is handled with the highest level of protection.
            </p>
            <h3>Character of the Day</h3>
            <p>
              The "Character of the Day" feature selects a random character from
              the Star Wars universe each day. The selection is based on the
              current date and ensures that a new character is showcased daily.
              The character is chosen randomly from the list of available
              characters in the SWAPI. The app stores the selected character ID
              and the date in local storage or in the user data (for logged-in
              users) to ensure that the same character is not repeated within
              the same day.
            </p>
            <p>
              This approach provides a fresh and engaging experience for users,
              highlighting different characters each day and keeping the content
              dynamic and interesting.
            </p>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            <h2>Über Star Wars</h2>
            <p>
              Star Wars ist eine ikonische Franchise, das von George Lucas
              geschaffen wurde und in einer weit entfernten Galaxie spielt. Die
              Saga folgt den Abenteuern einer Vielzahl von Figuren, darunter die
              mystischen Jedi, die finsteren Sith, charismatische Droiden und
              zahlreiche andere faszinierende Kreaturen. Mit dem 1977
              erschienenen Film "Star Wars" (später als "Episode IV – Eine neue
              Hoffnung" bekannt) begann eine epische Erzählung, die sich zu
              einem umfangreichen Universum aus Filmen, Serien, Büchern und mehr
              entwickelt hat.
            </p>
            <p>
              Im Zentrum der Geschichten steht der ewige Konflikt zwischen Licht
              und Dunkelheit, wobei nicht nur der Kampf der Jedi gegen die Sith,
              sondern auch Themen wie Macht, Freiheit, Rebellion und Schicksal
              eine zentrale Rolle spielen. Star Wars reflektiert archetypische
              Erzählstrukturen, in denen Heldenreisen, der Fall ins Verderben
              und die Erlösung tief verwurzelt sind. Dieser Mythos von Aufstieg,
              Fall und dem Streben nach Gleichgewicht in der Macht ist ein
              wesentliches Motiv, das die Trilogie, insbesondere die
              ursprünglichen drei Filme, durchzieht.
            </p>
            <p>
              Star Wars ist bekannt für sein epischen Erzählstil, unvergessliche
              Charaktere und besonders bahnbrechende Spezialeffekte bekannt und
              hat die Welt des Films durch den innovativen Einsatz von Technik
              revolutioniert. Die Saga hat das Publikum über Jahrzehnte hinweg
              begeistert und bleibt ein bedeutender Teil der Popkultur.
            </p>
          </Col>
          <Col xs={12}>
            <h2>Über diese App</h2>
            <p>
              Diese Anwendung, "Star Wars Charakter des Tages", wurde
              entwickelt, um das Star Wars Universum in greifbare Nähe zu
              bringen. Täglich stellt sie eine Auswahl an Star Wars-Charakteren
              vor und liefert umfassende Informationen zu jedem Einzelnen und
              dessen Bedeutung innerhalb der Saga.
            </p>
            <p>
              Die App wurde mit React für das Frontend entwickelt, wobei
              TypeScript für die Typensicherheit sorgt. Bootstrap wird für das
              Styling und Layout verwendet und bietet ein responsives Design,
              das sich an verschiedene Bildschirmgrößen anpasst. Die App ruft
              Charakterdaten von der Star Wars API (SWAPI) ab, um
              Charakterinformationen dynamisch anzuzeigen.
            </p>
            <p>
              Die Anwendung verwendet Redux für das Zustandsmanagement, was eine
              zentrale Kontrolle über den Anwendungszustand ermöglicht. Redux
              hilft bei der Verwaltung der Benutzerauthentifizierung, der
              Charakterdaten und anderer globaler Zustände.
            </p>
            <p>
              Aktionen und Reducer werden verwendet, um Benutzeranmeldung,
              Abmeldung und das Speichern von Benutzerinformationen wie
              Anmeldestatus und Benutzerdaten zu handhaben.
            </p>
            <p>Zu den wichtigsten Funktionen gehören:</p>
            <ul>
              <li>Anzeige einer Liste von Star Wars Charakteren</li>
              <li>
                Unendliches Scrollen, um beim Scrollen mehr Charaktere zu laden
              </li>
              <li>Eine Detailansicht der Informationen zu jedem Charakter</li>
              <li>
                Redux-Store-Management für die Handhabung des globalen Zustands
              </li>
              <li>Funktion zur Benutzererstellung und Anmeldung</li>
              <li>Feature "Charakter des Tages"</li>
            </ul>
            <h3>Benutzererstellung und Anmeldung</h3>
            <p>
              Benutzer können ein Konto erstellen und sich in der Anwendung
              anmelden. Nach der Anmeldung werden benutzerspezifische Daten wie
              Anmeldestatus und Benutzerinformationen im Redux-Zustand
              gespeichert. Diese Funktion gewährleistet ein personalisiertes
              Erlebnis und ermöglicht einen sicheren Zugriff auf Benutzerdaten.
            </p>
            <p>
              Die App integriert auch die Google Single Sign-On (SSO)-Funktion,
              um eine nahtlose und sichere Authentifizierungserfahrung zu
              bieten. Mit Google SSO können sich Benutzer mit ihren
              Google-Kontodaten anmelden, was den Anmeldeprozess vereinfacht und
              die Sicherheit erhöht.
            </p>
            <p>
              Um diese Funktion zu implementieren, haben wir Google SSO
              eingerichtet, indem wir OAuth 2.0-Anmeldeinformationen über die
              Google Cloud Console erstellt haben. Dies beinhaltete die
              Generierung einer Client-ID, die es der App ermöglicht, mit den
              Authentifizierungsservern von Google zu kommunizieren. Wenn
              Benutzer sich über Google anmelden möchten, werden sie zur
              Anmeldeseite von Google weitergeleitet, wo sie sicher ihre
              Anmeldedaten eingeben können.
            </p>
            <p>
              Nach der Authentifizierung erhält die App wichtige
              Benutzerinformationen, wie die E-Mail-Adresse des Benutzers,
              direkt von Google. Diese Informationen werden verwendet, um die
              Benutzererfahrung innerhalb der App zu personalisieren, ohne dass
              separate Anmeldeinformationen verwaltet werden müssen.
              <span className="text-decoration-underline ms-1">
                Google SSO ist nur mit einer spezifischen Gruppe von Konten
                möglich, die von uns aktiviert wurden, um sicherzustellen, dass
                nur autorisierte Benutzer auf die App zugreifen können. Die
                Liste dieser aktivierten Konten finden Sie in der README-Datei.
              </span>
            </p>
            <p>
              Die Integration von Google SSO vereinfacht nicht nur den
              Anmeldeprozess, sondern nutzt auch die Sicherheitsmaßnahmen von
              Google, um sicherzustellen, dass Benutzerdaten mit höchster
              Sorgfalt behandelt werden.
            </p>
            <h3>Charakter des Tages</h3>
            <p>
              Das Feature "Charakter des Tages" wählt jeden Tag einen zufälligen
              Charakter aus dem Star Wars Universum aus. Die Auswahl basiert auf
              dem aktuellen Datum und stellt sicher, dass täglich ein neuer
              Charakter präsentiert wird.
            </p>
            <p>
              Der Charakter wird zufällig aus der Liste der in der SWAPI
              verfügbaren Charaktere ausgewählt. Die App speichert die ID des
              ausgewählten Charakters und das Datum im lokalen Speicher oder in
              den Benutzerdaten (für angemeldete Benutzer), um sicherzustellen,
              dass derselbe Charakter nicht innerhalb desselben Tages wiederholt
              wird.
            </p>
            <p>
              Dieser Ansatz sorgt für ein frisches und ansprechendes Erlebnis
              für die Benutzer, indem er täglich verschiedene Charaktere
              hervorhebt und die Inhalte dynamisch und interessant hält.
            </p>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default About;
