import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

type User = {
  id: string;
  email: string;
  swcotdDate?: string;
  swcotdCharacterId?: string;
};

export const createUser = async (user: User): Promise<void> => {
  try {
    await api.post('/users', user);
  } catch (error) {
    console.error('Error adding new user:', error);
    throw new Error('Failed to add new user');
  }
};

export const loginUser = async (id: string): Promise<User> => {
  try {
    const response = await api.get(`/users/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching user with ID ${id}:`, error);
    throw new Error('Failed to fetch user');
  }
};

export const updateUser = async (id: string, user: User): Promise<void> => {
  try {
    await api.put(`/users/${id}`, user);
  } catch (error) {
    console.error(`Error updating user with ID ${id}:`, error);
    throw new Error('Failed to update user');
  }
};
