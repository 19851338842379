import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Character from './Character';
import { getCharacterById, getCharacters } from '../services/swapi';
import { updateUser } from '../services/users';
import { Spinner } from 'react-bootstrap';
import StarWarsLogo from '../assets/star-wars-logo.png';
import { RootState } from '../store/store';
import { CharacterType, UserData } from '../types';

const fetchCharacterOfTheDay = async (
  isLoggedIn: boolean,
  userData: UserData | null,
  setCharacter: (character: CharacterType) => void,
  setLoading: (loading: boolean) => void,
  setMessage: (message: string) => void,
) => {
  const today = new Date().toISOString().split('T')[0];
  let characterId: string | null = null;
  let message = '';

  try {
    if (isLoggedIn && userData) {
      const { swcotdDate, swcotdCharacterId } = userData;

      if (swcotdDate !== today || !swcotdCharacterId) {
        const characters = await getCharacters('https://swapi.dev/api/people/');
        const randomIndex = Math.floor(Math.random() * characters.count) + 1;
        characterId = randomIndex.toString();

        await updateUser(userData.id, {
          ...userData,
          swcotdDate: today,
          swcotdCharacterId: characterId,
        });
        message = `Hello ${userData.email}, today's character is:`;
      } else {
        characterId = swcotdCharacterId;
        message = `Hello ${userData.email}, today's character is:`;
      }
    } else {
      const storedDate = localStorage.getItem('swcotd_date');
      characterId = localStorage.getItem('swcotd_character_id');

      if (storedDate !== today || !characterId) {
        const characters = await getCharacters('https://swapi.dev/api/people/');
        const randomIndex = Math.floor(Math.random() * characters.count) + 1;
        characterId = randomIndex.toString();

        localStorage.setItem('swcotd_date', today);
        localStorage.setItem('swcotd_character_id', characterId);
        message = "This is today's character for everyone:";
      } else {
        message = "This is today's character for everyone:";
      }
    }

    if (characterId) {
      const fetchedCharacter = await getCharacterById(characterId);
      setCharacter(fetchedCharacter);
    }
  } catch (err) {
    console.error('Failed to fetch data', err);
  } finally {
    setLoading(false);
    setMessage(message);
  }
};

const CharacterOfTheDay = () => {
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const userData = useSelector((state: RootState) => state.user.userData);

  const [character, setCharacter] = useState<CharacterType | null>(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    fetchCharacterOfTheDay(
      isLoggedIn,
      userData,
      setCharacter,
      setLoading,
      setMessage,
    );
  }, [isLoggedIn, userData]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center spinner-height">
        <Spinner animation="border" variant="warning" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div>
      <div className="text-center">
        <img src={StarWarsLogo} alt="Star Wars Logo" height="150" />
        <h1>Character of the Day</h1>
        <p>{message}</p>
      </div>
      <div className="d-flex justify-content-center mt-5">
        {character ? (
          <Character character={character} />
        ) : (
          <p>No character available.</p>
        )}
      </div>
    </div>
  );
};

export default CharacterOfTheDay;
