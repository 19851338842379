import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Navbar as BootstrapNavbar,
  Nav,
  Container,
  Button,
  Modal,
  Form,
} from 'react-bootstrap';
import StarWarsLogo from '../assets/star-wars-logo.png';
import { createUser, loginUser } from '../services/users';
import {
  CredentialResponse,
  GoogleLogin,
  googleLogout,
} from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { login, logout } from '../store/userSlice';
import { RootState } from '../store/store';
import { jwtDecode } from 'jwt-decode';
import { CustomJwtPayload } from '../types';
import { useLocation } from 'react-router-dom';
import { useToast } from './ToastContext';

const Navbar: React.FC = () => {
  const [showCreateUserModal, setShowCreateUserModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(true);

  const dispatch = useDispatch();
  const location = useLocation();
  const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
  const { show } = useToast();

  const validateEmail = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailChange = (e: { target: { value: string } }) => {
    const value = e.target.value;
    setEmail(value);
    setValidEmail(validateEmail(value));
  };

  const resetForm = () => {
    setEmail('');
    setValidEmail(true);
  };

  const handleCreateUser = async () => {
    try {
      let userExists = false;
      try {
        const existingUserData = await loginUser(email);
        userExists = !!existingUserData;
      } catch (error) {
        console.error('Error checking user existence:', error);
      }
      if (userExists) {
        show('User already exists with this email.', 'danger');
        return;
      }
      await createUser({ id: email, email: email });
      show('User created successfully!', 'success');
      setShowCreateUserModal(false);
      setEmail('');
    } catch (error) {
      console.error('Error creating user:', error);
      show('Error creating user. Please try again.', 'danger');
    }
  };

  const handleLogin = async (email: string) => {
    try {
      const userData = await loginUser(email);
      dispatch(login(userData));
      show('Login successful!', 'success');
      setShowLoginModal(false);
      setEmail('');
    } catch (error) {
      console.error('Error logging in:', error);
      show('Error logging in. Please try again.', 'danger');
    }
  };

  const handleGoogleSignIn = async (response: CredentialResponse) => {
    if (response.credential) {
      const userObject = jwtDecode<CustomJwtPayload>(response.credential);
      await handleLogin(userObject.email);
    } else {
      console.error('Credential is undefined');
    }
  };

  const handleLogout = () => {
    googleLogout();
    dispatch(logout());
  };

  return (
    <>
      <BootstrapNavbar bg="dark" variant="dark" expand="lg" sticky="top">
        <Container>
          <BootstrapNavbar.Brand as={Link} to="/">
            <img src={StarWarsLogo} alt="Star Wars Logo" height="40" />
          </BootstrapNavbar.Brand>
          <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
          <BootstrapNavbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                as={Link}
                to="/"
                className={location.pathname === '/' ? 'text-warning' : ''}
              >
                Home
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/character-of-the-day"
                className={
                  location.pathname === '/character-of-the-day'
                    ? 'text-warning'
                    : ''
                }
              >
                Character of the Day
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/about"
                className={location.pathname === '/about' ? 'text-warning' : ''}
              >
                About
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto">
              {!isLoggedIn ? (
                <>
                  <Button
                    variant="outline-warning"
                    className="me-2"
                    onClick={() => setShowCreateUserModal(true)}
                  >
                    Create User
                  </Button>
                  <Button
                    className="me-2"
                    variant="outline-warning"
                    onClick={() => setShowLoginModal(true)}
                  >
                    Login
                  </Button>
                  <GoogleLogin onSuccess={handleGoogleSignIn} />
                </>
              ) : (
                <Button variant="outline-warning" onClick={handleLogout}>
                  Logout
                </Button>
              )}
            </Nav>
          </BootstrapNavbar.Collapse>
        </Container>
      </BootstrapNavbar>

      <Modal
        show={showCreateUserModal}
        onHide={() => {
          resetForm();
          setShowCreateUserModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!validEmail}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!email || !validEmail}
            variant="outline-warning"
            onClick={handleCreateUser}
          >
            Create User
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showLoginModal}
        onHide={() => {
          resetForm();
          setShowLoginModal(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={handleEmailChange}
                isInvalid={!validEmail}
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!email || !validEmail}
            variant="outline-warning"
            onClick={() => handleLogin(email)}
          >
            Login
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Navbar;
