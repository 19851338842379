import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../types';
interface UserState {
  userData: UserData;
  isLoggedIn: boolean;
}

const initialState: UserState = {
  userData: {
    id: '',
    email: '',
    swcotdDate: '',
    swcotdCharacterId: '',
  },
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        id: string;
        email: string;
        swcotdDate?: string;
        swcotdCharacterId?: string;
      }>,
    ) => {
      state.userData = {
        id: action.payload.id,
        email: action.payload.email,
        swcotdDate: action.payload.swcotdDate,
        swcotdCharacterId: action.payload.swcotdCharacterId,
      };
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.userData = {
        id: '',
        email: '',
        swcotdDate: '',
        swcotdCharacterId: '',
      };
      state.isLoggedIn = false;
    },
  },
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
