import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import CharacterOfTheDay from './components/CharacterOfTheDay';
import { ToastProvider } from './components/ToastContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <>
      <ToastProvider>
        <Navbar />
        <div className="video-container">
          <video autoPlay muted loop className="video-background">
            <source src="/background.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="container mt-4">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route
                path="/character-of-the-day"
                element={<CharacterOfTheDay />}
              />
              <Route path="/about" element={<About />} />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </div>
      </ToastProvider>
    </>
  );
}

export default App;
