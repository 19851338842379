import React, { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { getCharacters } from '../services/swapi';
import Character from './Character';
import { Spinner, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CharacterType } from '../types';
import { useToast } from './ToastContext';

const Home: React.FC = () => {
  const [characters, setCharacters] = useState<CharacterType[]>([]);
  const [nextPageUrl, setNextPageUrl] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const { show } = useToast();

  const fetchCharacters = useCallback(async (url: string) => {
    try {
      const characterData = await getCharacters(url);
      setCharacters((prev) => [...prev, ...characterData.results]);
      setNextPageUrl(characterData.next);
      setHasMore(!!characterData.next);
    } catch (error) {
      show('Error fetching characters!', 'danger');
      console.error('Error fetching characters:', error);
    }
  }, []);

  useEffect(() => {
    fetchCharacters('https://swapi.dev/api/people/');
  }, [fetchCharacters]);

  return (
    <div className="container mt-4">
      <h1>Welcome to the Star Wars Character of the Day!</h1>
      <div className="d-flex align-items-center">
        <span>
          Discover a new Star Wars character every day and explore their
          stories.
        </span>
        <Nav.Link
          as={Link}
          to="/character-of-the-day"
          className="ms-2 text-warning"
        >
          {'Discover Yours!'}
        </Nav.Link>
      </div>

      <p>
        Our app brings you a daily dose of Star Wars lore right to your screen.
      </p>

      <InfiniteScroll
        dataLength={characters.length}
        next={() => {
          if (nextPageUrl) {
            fetchCharacters(nextPageUrl);
          }
        }}
        className="mt-5"
        hasMore={hasMore}
        loader={
          <div className="d-flex justify-content-center align-items-center spinner-height">
            <Spinner animation="border" variant="warning" />
          </div>
        }
        endMessage={<p className="text-center">No more characters to load</p>}
      >
        <div className="row">
          {characters.map((character) => (
            <div
              className="col-md-6 col-lg-3 mb-5 d-flex justify-content-center"
              key={character.name}
            >
              <Character character={character} />
            </div>
          ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default Home;
