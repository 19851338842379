import React, { useState } from 'react';
import { Modal, Button, Card, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  getPlanetsById,
  getFilmsById,
  getSpeciesById,
  getVehiclesById,
  getStarshipsById,
} from '../services/swapi';
import {
  CharacterType,
  Planet,
  Film,
  Species,
  Vehicle,
  Starship,
} from '../types';
import { useLocation } from 'react-router-dom';
import { useToast } from './ToastContext';
interface CharacterProps {
  character: CharacterType;
}

const Character: React.FC<CharacterProps> = ({ character }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planet, setPlanet] = useState<Planet>();
  const [films, setFilms] = useState<Film[]>([]);
  const [species, setSpecies] = useState<Species[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [starships, setStarships] = useState<Starship[]>([]);
  const { show } = useToast();
  const location = useLocation();

  const handleClose = () => setShowModal(false);
  const handleShow = () => {
    setShowModal(true);
    fetchAdditionalData();
  };

  const fetchAdditionalData = async () => {
    setIsLoading(true);
    try {
      const [planetData, filmsData, speciesData, vehiclesData, starshipsData] =
        await Promise.all([
          getPlanetsById(character.homeworld),
          Promise.all(character.films.map((film) => getFilmsById(film))),
          Promise.all(
            character.species.map((specie) => getSpeciesById(specie)),
          ),
          Promise.all(
            character.vehicles.map((vehicle) => getVehiclesById(vehicle)),
          ),
          Promise.all(
            character.starships.map((starship) => getStarshipsById(starship)),
          ),
        ]);

      setPlanet(planetData);
      setFilms(filmsData);
      setSpecies(speciesData);
      setVehicles(vehiclesData);
      setStarships(starshipsData);
    } catch (error) {
      show('Error fetching additional data!', 'danger');
      console.error('Error fetching additional data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPhotoPath = (url: string): string => {
    const match = url.match(/people\/(\d+)\/$/);
    const id = match ? match[1] : null;
    return id ? `/people/${id}.jpg` : '/people/default.png';
  };

  const capitalizeFirstLetter = (string: string): string => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div
      className={
        location.pathname === '/character-of-the-day'
          ? 'character-of-the-day-card'
          : 'd-flex flex-column h-100'
      }
    >
      <Card className="character-card h-100">
        <Card.Img
          variant="top"
          src={getPhotoPath(character.url)}
          className="character-card__img"
        />
        <Card.Body className="d-flex flex-column">
          <Card.Title className="mb-auto mt-auto">{character.name}</Card.Title>
          <Card.Text>
            <strong>Birth Year:</strong> {character.birth_year}
            <br />
            <strong>Gender:</strong> {capitalizeFirstLetter(character.gender)}
          </Card.Text>
          <Button variant="outline-warning" onClick={handleShow}>
            Show More Details
          </Button>
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{character.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center spinner-height">
              <Spinner animation="border" variant="warning" />
            </div>
          ) : (
            <>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <h4 className="text-warning">Character Details</h4>
                  <p>
                    <strong>Birth Year:</strong>{' '}
                    {capitalizeFirstLetter(character.birth_year)}
                  </p>
                  <p>
                    <strong>Gender:</strong>{' '}
                    {capitalizeFirstLetter(character.gender)}
                  </p>
                  <p>
                    <strong>Height:</strong>{' '}
                    {capitalizeFirstLetter(
                      character.height === 'unknown'
                        ? character.height
                        : `${character.height} cm`,
                    )}
                  </p>
                  <p>
                    <strong>Weight:</strong>{' '}
                    {capitalizeFirstLetter(
                      character.mass === 'unknown'
                        ? character.mass
                        : `${character.mass} kg`,
                    )}
                  </p>
                  <p>
                    <strong>Hair Color:</strong>{' '}
                    {capitalizeFirstLetter(character.hair_color)}
                  </p>
                  <p>
                    <strong>Skin Color:</strong>{' '}
                    {capitalizeFirstLetter(character.skin_color)}
                  </p>
                  <p>
                    <strong>Eye Color:</strong>{' '}
                    {capitalizeFirstLetter(character.eye_color)}
                  </p>
                </div>

                <div className="col-md-6 mb-4">
                  <h4 className="text-warning">Homeworld</h4>
                  {planet ? (
                    <>
                      <p>
                        <strong>Name:</strong>{' '}
                        {capitalizeFirstLetter(planet.name)}
                      </p>
                      <p>
                        <strong>Climate:</strong>{' '}
                        {capitalizeFirstLetter(planet.climate)}
                      </p>
                      <p>
                        <strong>Terrain:</strong>{' '}
                        {capitalizeFirstLetter(planet.terrain)}
                      </p>
                    </>
                  ) : (
                    <p>No planet data</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-4">
                  <h5 className="text-warning">Films</h5>
                  {films.length ? (
                    <ul className="list-unstyled">
                      {films.map((film) => (
                        <li key={film.title}>• {film.title}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No films</p>
                  )}
                </div>

                <div className="col-md-6 mb-4">
                  <h5 className="text-warning">Species</h5>
                  {species.length ? (
                    <ul className="list-unstyled">
                      {species.map((specie) => (
                        <li key={specie.name}>• {specie.name}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No species</p>
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6 mb-4">
                  <h5 className="text-warning">Vehicles</h5>
                  {vehicles.length ? (
                    <ul className="list-unstyled">
                      {vehicles.map((vehicle) => (
                        <li key={vehicle.name}>• {vehicle.name}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No vehicles</p>
                  )}
                </div>

                <div className="col-md-6 mb-4">
                  <h5 className="text-warning">Starships</h5>
                  {starships.length ? (
                    <ul className="list-unstyled">
                      {starships.map((starship) => (
                        <li key={starship.name}>• {starship.name}</li>
                      ))}
                    </ul>
                  ) : (
                    <p>No starships</p>
                  )}
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-warning" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Character;
