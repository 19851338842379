// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-background {
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
}

.container {
  position: relative;
  z-index: 1;
  color: white;
}

.container h1,
.container h2,
.container h3,
.container p {
  color: white;
}

.container a {
  color: #f8f9fa;
  text-decoration: none;
}

.container a:hover {
  text-decoration: underline;
}

.container h1,
.container h2,
.container h3,
.container p {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;;AAEA;;;;EAIE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;;;;EAIE,2CAA2C;AAC7C","sourcesContent":[".video-background {\n  position: fixed;\n  top: 0;\n  left: 0;\n  object-fit: cover;\n}\n\n.container {\n  position: relative;\n  z-index: 1;\n  color: white;\n}\n\n.container h1,\n.container h2,\n.container h3,\n.container p {\n  color: white;\n}\n\n.container a {\n  color: #f8f9fa;\n  text-decoration: none;\n}\n\n.container a:hover {\n  text-decoration: underline;\n}\n\n.container h1,\n.container h2,\n.container h3,\n.container p {\n  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
